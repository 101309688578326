<template>
  <div>
    <canvas id="canvas">
      HTML5 canvas appears to be unsupported in the current browser.<br />
      Please try updating or use a different browser.
    </canvas>
    <div id="status">
      <div
        id="status-progress"
        style="display: none;"
        oncontextmenu="event.preventDefault();"
      >
        <div id="status-progress-inner"></div>
      </div>
      <div
        id="status-indeterminate"
        style="display: none;"
        oncontextmenu="event.preventDefault();"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div id="status-notice" class="godot" style="display: none;"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Godot",
  props: {
    EXECUTABLE_NAME: null,
    MAIN_PACK: null
  }
};
</script>

<style></style>
