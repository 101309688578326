<template src="./Game.html"> </template>

<script>
import { mapGetters } from "vuex";
import Unity from "vue-unity-webgl";
import Godot from "../../components/Godot/Godot.vue";
import configService from "../../configs/config";

export default {
  name: "game",
  components: { Unity, Godot },
  data() {
    return {
      message: "",
      gameId: null,
      games: [
        // Init Ciencia e ingeniería de datos
        {
          name: "Ciencia e ingeniería de datos",
          module: "Módulo 1",
          slug: "60e8b67c0e88073e9527b7f2",
          src:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/Curso Ciencia e Ingeniería de Datos Mod 1.json",
          unityLoader:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Ciencia e ingeniería de datos",
          module: "Módulo 2",
          slug: "60e8b67c0e88073e9527b7f3",
          src:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/Curso Ciencia e Ingeniería de Datos Mod 1.json",
          unityLoader:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Ciencia e ingeniería de datos",
          module: "Módulo 3",
          slug: "/60e8b67c0e88073e9527b7f4",
          src:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/Curso Ciencia e Ingeniería de Datos Mod 1.json",
          unityLoader:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Ciencia e ingeniería de datos",
          module: "Módulo 4",
          slug: "60e8b67c0e88073e9527b7f5",
          src:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/Curso Ciencia e Ingeniería de Datos Mod 1.json",
          unityLoader:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Ciencia e ingeniería de datos",
          module: "Módulo 5",
          slug: "/60e8b67c0e88073e9527b7f6",
          src:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/Curso Ciencia e Ingeniería de Datos Mod 1.json",
          unityLoader:
            "/Games/Unity/Cienciaeingenieriadedatos/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        // Finish Ciencia e ingeniería de datos
        // Init Curso Industria 4RI
        {
          name: "Curso Industria 4RI",
          module: "Módulo 1",
          slug: "60e8b67c0e88073e9527b804",
          src:
            "/Games/Unity/Industria4ri/Module1/Build/Curso Industria 4RI Mod 1.json",
          unityLoader: "/Games/Unity/Industria4ri/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Curso Industria 4RI",
          module: "Módulo 2",
          slug: "60e8b67c0e88073e9527b805",
          src:
            "/Games/Unity/Industria4ri/Module2/Build/Curso Industria 4RI Mod 2.json",
          unityLoader: "/Games/Unity/Industria4ri/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Curso Industria 4RI",
          module: "Módulo 3",
          slug: "60e8b67c0e88073e9527b806",
          src:
            "/Games/Unity/Industria4ri/Module2/Build/Curso Industria 4RI Mod 2.json",
          unityLoader: "/Games/Unity/Industria4ri/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Curso Industria 4RI",
          module: "Módulo 4",
          slug: "60e8b67c0e88073e9527b807",
          src:
            "/Games/Unity/Industria4ri/Module2/Build/Curso Industria 4RI Mod 2.json",
          unityLoader: "/Games/Unity/Industria4ri/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Curso Industria 4RI",
          module: "Módulo 5",
          slug: "60e8b67c0e88073e9527b808",
          src:
            "/Games/Unity/Industria4ri/Module5/Build/Curso Industria 4RI Mod 5.json",
          unityLoader: "/Games/Unity/Industria4ri/Module5/Build/UnityLoader.js",
          engine: "unity"
        },
        // Finish Curso Industria 4RI
        //Init Bid Data
        {
          name: "Big Data",
          module: "Módulo 1",
          slug: "60e8b67c0e88073e9527b810",
          src: "/Games/Unity/BigData/Module1/Build/Curso Big Data Mod 1.json",
          unityLoader: "/Games/Unity/BigData/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Big Data",
          module: "Módulo 2",
          slug: "60e8b67c0e88073e9527b811",
          src: "/Games/Unity/BigData/Module2/Build/Curso Big Data Mod 2.json",
          unityLoader: "/Games/Unity/BigData/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Big Data",
          module: "Módulo 3",
          slug: "60e8b67c0e88073e9527b812",
          src: "/Games/Unity/BigData/Module3/Build/Curso Big Data Mod 3.json",
          unityLoader: "/Games/Unity/BigData/Module3/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Big Data",
          module: "Módulo 4",
          slug: "60e8b67c0e88073e9527b813",
          src: "/Games/Unity/BigData/Module4/Build/Curso Big Data Mod 4.json",
          unityLoader: "/Games/Unity/BigData/Module4/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Big Data",
          module: "Módulo 5",
          slug: "60e8b67c0e88073e9527b814",
          src: "/Games/Unity/BigData/Module4/Build/Curso Big Data Mod 4.json",
          unityLoader: "/Games/Unity/BigData/Module4/Build/UnityLoader.js",
          engine: "unity"
        },
        //Finish Bid Data
        //Init Habitat 4RI
        {
          name: "Habitat 4RI",
          module: "Módulo 1",
          slug: "60e8b67c0e88073e9527b7fe",
          src: "/Games/Unity/Habitat4ri/Module1/Build/Curso Habitat 4RI Mod 1.json",
          unityLoader: "/Games/Unity/Habitat4ri/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Habitat 4RI",
          module: "Módulo 2",
          slug: "60e8b67c0e88073e9527b7ff",
          src: "/Games/Unity/Habitat4ri/Module2/Build/Curso Habitat 4RI Mod 2.json",
          unityLoader: "/Games/Unity/Habitat4ri/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Habitat 4RI",
          module: "Módulo 3",
          slug: "60e8b67c0e88073e9527b800",
          src: "/Games/Unity/Habitat4ri/Module2/Build/Curso Habitat 4RI Mod 2.json",
          unityLoader: "/Games/Unity/Habitat4ri/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Habitat 4RI",
          module: "Módulo 4",
          slug: "60e8b67c0e88073e9527b801",
          src: "/Games/Unity/Habitat4ri/Module2/Build/Curso Habitat 4RI Mod 2.json",
          unityLoader: "/Games/Unity/Habitat4ri/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Habitat 4RI",
          module: "Módulo 5",
          slug: "60e8b67c0e88073e9527b802",
          src: "/Games/Unity/Habitat4ri/Module2/Build/Curso Habitat 4RI Mod 2.json",
          unityLoader: "/Games/Unity/Habitat4ri/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        //Finish Habitat 4RI
        //Init Educación para el talento 4RI
        {
          name: "Educación para el talento 4RI",
          module: "Módulo 1",
          slug: "60e8b67c0e88073e9527b80a",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module1/Build/Curso Educacion para el Talento 4RI Mod 1.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Educación para el talento 4RI",
          module: "Módulo 2",
          slug: "60e8b67c0e88073e9527b80b",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module2/Build/Curso Educacion para el Talento 4RI Mod 2.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Educación para el talento 4RI",
          module: "Módulo 3",
          slug: "60e8b67c0e88073e9527b80c",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module2/Build/Curso Educacion para el Talento 4RI Mod 2.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Educación para el talento 4RI",
          module: "Módulo 4",
          slug: "60e8b67c0e88073e9527b80d",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module4/Build/Curso Educacion para el Talento 4RI Mod 4.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module4/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Educación para el talento 4RI",
          module: "Módulo 5",
          slug: "60e8b67c0e88073e9527b80e",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module5/Build/Curso Educacion para el Talento 4RI Mod 5.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module5/Build/UnityLoader.js",
          engine: "unity"
        },
        // Finish Educación para el talento 4RI
        //Herramientas de programación 
        {
          name: "Herramientas de programación",
          module: "Módulo 1",
          slug: "60e8b67c0e88073e9527b7f8",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module1/Build/Curso Educacion para el Talento 4RI Mod 1.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module1/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Herramientas de programación",
          module: "Módulo 2",
          slug: "60e8b67c0e88073e9527b7f9",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module2/Build/Curso Educacion para el Talento 4RI Mod 2.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Herramientas de programación",
          module: "Módulo 3",
          slug: "60e8b67c0e88073e9527b7fa",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module2/Build/Curso Educacion para el Talento 4RI Mod 2.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module2/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Herramientas de programación",
          module: "Módulo 4",
          slug: "60e8b67c0e88073e9527b7fb",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module4/Build/Curso Educacion para el Talento 4RI Mod 4.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module4/Build/UnityLoader.js",
          engine: "unity"
        },
        {
          name: "Herramientas de programación",
          module: "Módulo 5",
          slug: "60e8b67c0e88073e9527b7fc",
          src:
            "/Games/Unity/Educacionparaeltalento4RI/Module5/Build/Curso Educacion para el Talento 4RI Mod 5.json",
          unityLoader:
            "/Games/Unity/Educacionparaeltalento4RI/Module5/Build/UnityLoader.js",
          engine: "unity"
        },
        // Finish Herramientas de programación
      ],
      src: null,
      unityLoader: null,
      typeGame: null
    };
  },
  destroyed() {
      location.reload();
  },
  created() {
    this.gameId = this.$route.params.id;
  },
  mounted() {
    this.games.map(game => {
      if (game.slug === this.gameId) {
        if (game.engine === "godot") {
          return "";
        } else if (game.engine === "unity") {
          this.typeGame = game.engine;
          this.src = game.src;
          this.unityLoader = game.unityLoader;
        }
      }
    });
  },
  computed: {
    ...mapGetters({
      getJwt: "getJwt"
    })
  },
  methods: {
    sendText() {
      const SendToUnityVarible = this.getJwt + "|" + configService.apiNodeUrl;
      this.$refs.myInstance.message(
        "Bridge",
        "SendToUnity",
        SendToUnityVarible
      );
    }
  }
};
</script>

<style lang="scss" scoped src="./Game.scss"></style>
